import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "productGroupingCheckbox",
    "selectedActions",
    "filteringActions",
    "selectedItemCount",
    "productGroup",
  ];

  static values = {
    selectedOneLabel: String,
    selectedManyLabel: String,
  };

  totalGoods = 0;

  connect() {
    this.configure();
  }

  toggleProductGroupingCheckbox() {
    this.configure();
  }

  cancelSelections() {
    this.productGroupingCheckboxTargets.forEach((productGrouping) => {
      productGrouping.checked = false;
    });

    this.configure();
  }

  configure() {
    const productGroups = this.productGroupingCheckboxTargets.filter((productGrouping) => productGrouping.checked);
    this.totalGoods = productGroups.reduce(
      (accumulator, productGroup) => parseInt(productGroup.dataset.totalGoods, 10) + accumulator,
      0,
    );

    const selected = this.totalGoods === 1 ? this.selectedOneLabelValue : this.selectedManyLabelValue;
    this.selectedItemCountTarget.textContent = `${this.totalGoods} ${selected}`;

    this.selectedActionsTarget.classList.toggle("hidden", !this.totalGoods > 0);
    this.filteringActionsTarget.classList.toggle("hidden", this.totalGoods > 0);

    this.configureLineItemCounts();
    this.configureGroupCounts();
  }

  configureGroupCounts() {
    this.productGroupTargets.forEach((productGroup) => {
      let displayedAllocated = 0;
      let totalCount = 0;
      productGroup.querySelectorAll("[role=product] [role=productGroupingSize]").forEach((productGroupingSize) => {
        totalCount += parseInt(productGroupingSize.dataset.sizeCount, 10);
        displayedAllocated += parseInt(productGroupingSize.dataset.displayedAllocated, 10);
      });

      productGroup.querySelector("[role=groupHeaderTotals]").textContent = `${displayedAllocated}/${totalCount}`;
    });
  }

  configureLineItemCounts() {
    this.productGroupingCheckboxTargets.forEach((productGroupingCheckbox) => {
      const productGrouping = productGroupingCheckbox.closest(".group");

      productGrouping.querySelectorAll("[role=productGroupingSize]").forEach((productGroupingSize) => {
        const label = productGroupingSize.dataset.sizeLabel;
        const count = productGroupingSize.dataset.sizeCount;
        let allocated = count;

        if (!productGroupingCheckbox.checked) {
          allocated = productGroupingSize.dataset.sizeAllocated;
        }
        productGroupingSize.dataset.displayedAllocated = allocated;
        productGroupingSize.textContent = `${label} (${allocated}/${count})`;
      });
    });
  }
}
